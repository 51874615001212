<template>
	<div>
		<H5header :type="2"></H5header>
		
		<div class="nian">
			<div style="display: flex;align-items: center;">
				<img src="../../assets/image/group.png" alt="">
				<p>关键字</p>
			</div>
			<el-input placeholder="关键字" v-model="keyword" style="width: 194PX;"></el-input>
		</div>
		<div class="xian2"></div>
		<div class="nian">
			<div style="display: flex;align-items: center;">
				<img src="../../assets/image/Event.png" alt="">
				<p>栏目</p>
			</div>
			<div class="select">
				<!-- <el-select placeholder="请选择" filterable v-model="lmid" clearable>
					<el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select> -->
				<a-select option-label-prop="label"  placeholder="请选择" v-model="lmid" allowClear >
					<a-select-option v-for="item in options1" :key="item.id" :value="item.id" :label="item.name">
						<a-icon type="check" v-if="item.id===lmid"/> 
						{{item.name}}
					</a-select-option>
				</a-select>
			</div>
		</div>
		<div class="xian2"></div>
		
		<div class="but" @click="searchfor">
			搜索
		</div>
		<div class="xian"></div>
		<div class="articlelist" v-for="(item,index) in list" :key="index" @click="jump(item)">
			<div class="title">{{item.title}}</div>
			<div class="date">{{item.createTime | format}}</div>
			<div class="xian"></div>
		</div>
		
		<div class="paging">
			<pagination :total="total" @currentchange="currentchange" :type="2" :go="12" :pageSize="12" :pageNo="pageNum"></pagination>
		</div>
	</div>
</template>

<script>
	import H5header from "./components/header2.vue"
	export default {
		name: '',
		components: {
			H5header
		},
		data() {
			return {
				keyword:'',
				options1:[],
				pageNum: 1,
				pageSize: 20,
				total: 0,
				list:[],
				lmid:undefined
			}
		},
		created() {
			this.keyword = this.$route.query.keyword
			this.search()
			this.columnList()
			
			this.$getwxsdk(this.$route.query.keyword + ' - 北京市体育竞赛管理和国际交流中心')
		},
		filters: {
			format(x) {
				if(x != null && x != ''){
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			columnList(){
				this.$api.columnList().then(res=>{
					this.options1 = res.data.data
				})
			},
			currentchange(val) {
				this.pageNum = val
				this.search()
			},
			searchfor(){
				this.pageNum = 1
				this.search()
			},
			search() {
				let data = {
					keyword:this.keyword ,
					pageNo: this.pageNum,
					pageSize: this.pageSize,
					id:this.lmid
				}
				this.$api.search(data).then(res => {
					this.total = res.data.data.total
					this.list = res.data.data.records
					this.list.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(item) {
				this.$router.push({
					path: '/h5/H5articledetails',
					query: {
						id: item.id,
						refresh: new Date().getTime(),
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.nian {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 22PX;
	
		img {
			width: 17PX;
			height: 17PX;
			flex-shrink: 0;
		}
	
		p {
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin-left: 12PX;
			flex-shrink: 0;
		}
	
		.select ::v-deep .el-select .el-input .el-input__inner {
			border-color: transparent !important;
			background-color: transparent !important;
			box-shadow: none !important;
			color: #000;
		}
	
		.select ::v-deep .el-select .el-input,
		.select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		}
	
		.select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
			transform: rotateZ();
		}
	
		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #C0C4CC;
			font-size: 14PX;
			transition: transform .3s;
			transform: rotateZ(89deg);
			cursor: pointer;
			left: 15PX;
			position: relative;
		}
	
		.select ::v-deep input::-webkit-input-placeholder {
			color: #000;
		}
	
		.select ::v-deep .el-select .el-input .el-select__caret {
			color: #000;
		}
	
		::v-deep .el-input .el-input__inner {
			border: none !important;
			padding: 0 12PX;
		}
	
		::v-deep input::-webkit-input-placeholder {
			color: #000;
		}
	}
	
	.xian2 {
		margin: 0 22PX;
		height: 1PX;
		background: rgba(194, 194, 194, 0.2);
	}
	
	.but {
		margin: 20PX 22PX 0;
		width: calc(100% - 56PX);
		height: 37PX;
		background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
		border-radius: 50PX;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
		margin-bottom: 20PX;
	}
	
	.articlelist {
	
		.title {
			height: 36PX;
			font-size: 14PX;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			margin: 17PX 22PX 0 22PX;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	
		.date {
			font-size: 11PX;
			font-family: Source Han Sans CN;
			font-weight: 400;
			margin-top: 5PX;
			margin-right: 23PX;
			text-align: right;
			margin-bottom: 12PX;
		}
	
	
		.imgbox {
			margin: 17PX 22PX 0 22PX;
			display: flex;
			justify-content: space-between;
	
			img {
				width: 114PX;
				height: 70PX;
				border-radius: 10PX;
				flex-shrink: 0;
			}
	
			.title-img {
				height: 73PX;
				font-size: 14PX;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				line-clamp: 4;
				-webkit-box-orient: vertical;
				margin-right: 10PX;
			}
		}
	}
	
	.news-date {
		width: 72PX;
		height: 72PX;
		border: 1PX solid #bfd4e9;
		float: left;
		margin-right: 20PX;
		font-family: arial;
		color: #6198e2;
		background: #fff;
		text-align: center;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}
	
	.news-date b {
		height: 43PX;
		line-height: 42PX;
		font-size: 26PX;
	}
	
	.news-date p {
		font-size: 14PX;
		margin: 0 5PX;
		height: 26PX;
		line-height: 26PX;
		color: #6198e2;
		background: #fff;
		border-top: 1PX solid #dfe7f3;
		transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
	}
	
	h2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		line-clamp: 4;
		-webkit-box-orient: vertical;
	}
	
	.paging {
		margin: 0 22PX 22PX;
		display: flex;
		align-items: center;
	}
	
	.paging span {
		margin-right: 5PX;
	}
	
	.xian {
		width: 100%;
		height: 4PX;
		background: #F4F8FB;
	}
</style>